export default [
    {
        text: 'Structure',
        value: 'structure/main',
        icon: 'mdi-sitemap-outline' ,
        path: 'structure/main',
        permission:'MODULE_WESTUDY',
        id :'structure/main',
        owner : true
    },

]
